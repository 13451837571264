<template>
  <div
    class="postItem"
    :class="{ isPostDetails: hiddenOtp }"
    @click="details(postInfo)"
  >
    <!--        <div class="reviewStatus" :style="`color: ${postInfo.status == 1 ? '#7145e7' : '#e52e52'}`"-->
    <!--             v-if="isCreateCenter">-->
    <!--            状态 {{ postInfo.status == 1 ? "审核成功" : postInfo.status == 0 ? "审核中" : "审核失败" }}-->
    <!--        </div>-->
    <!--        <div class="optBox" v-if="isCreateCenter">-->
    <!--            <div class="optBtn" v-if="postInfo.status != 1" @click.stop="delPost">删除</div>-->
    <!--            <div class="optBtn" v-if="postInfo.status == 2" @click.stop="checkReview">查看原因</div>-->
    <!--        </div>-->
    <div class="communityHead">
      <div class="userContent">
        <div class="userInfoBox">
          <div class="userInfo">
            <div class="userInfoLeft">
              <div class="userName">
                <div
                  class="portrait"
                  @click.stop="jumpUserHome(postInfo.publisher)"
                >
                  <ImgDecypt
                    class="avatarImg"
                    :src="postInfo.publisher.portrait"
                    round
                  >
                  <ImgDecypt
                    class="avatarBorder"
                    :src="postInfo.publisher.portraitFrame"
                    :key="postInfo.publisher.portraitFrame"
                    v-if="postInfo.publisher.portraitFrame"
                  />
                    <!-- <img v-if="(postInfo.publisher.officialCertExpire && new Date(postInfo.publisher.officialCertExpire).getTime() > new Date().getTime()) || (postInfo.publisher.superUserExpire && new Date(postInfo.publisher.superUserExpire).getTime() > new Date().getTime())" class="authIcon" src="@/assets/png/authenticationIcon.png"> -->
<!--                    <img-->
<!--                      v-if="-->
<!--                        postInfo.publisher.superUser >= 1 &&-->
<!--                        postInfo.publisher.superUserExpire &&-->
<!--                        new Date(postInfo.publisher.superUserExpire).getTime() >-->
<!--                          new Date().getTime()-->
<!--                      "-->
<!--                      class="authIcon"-->
<!--                      src="@/assets/png/authenticationIcon.png"-->
<!--                    />-->
                  </ImgDecypt>
                </div>
                <div class="nameBox">
                  <div class="names">
                    <span>{{ postInfo.publisher.name }}</span>
                    <span
                      class="supreme"
                      v-if="
                        postInfo.publisher.videoFreeExpiration &&
                        new Date(
                          postInfo.publisher.videoFreeExpiration
                        ).getTime() > new Date().getTime()
                      "
                      >至尊会员</span
                    >
                    <img
                      v-else-if="postInfo.publisher.vipLevel"
                      class="vip"
                      src="@/assets/png/userVip.png"
                    />
                    <img class="superExpire" v-if="postInfo.publisher.superUserExpire && new Date(postInfo.publisher.superUserExpire).getTime() > new Date().getTime()" src="@/assets/png/superUserExpire.png" />
                  </div>
                  <div class="releaseTime">
                    <!--                                   <img class="sexIcon" v-if="postInfo.publisher.gender == 'male'" src="@/assets/png/maleIcon.png">-->
                    <!--                                   <img class="sexIcon" v-else src="@/assets/png/femaleIcon.png">-->
                    <span>{{ fromNowTimeToDate3(postInfo.reviewAt) }}</span>
                  </div>
                </div>
                <!--            <span class="merchant"-->
                <!--              v-if="postInfo.publisher.officialCertExpire && new Date(postInfo.publisher.officialCertExpire).getTime() > new Date().getTime()">认证商家</span>-->
                <!--            <span class="blogger"-->
                <!--              v-if="postInfo.publisher.superUserExpire && new Date(postInfo.publisher.superUserExpire).getTime() > new Date().getTime()">认证博主</span>-->
                <!--            <span class="topCommunity" v-if="postInfo.isTopping">置顶</span>-->
              </div>
            </div>

            <div
              class="cause"
              v-if="postInfo.status == 2"
              @click.stop="checkReview"
            >
              查看原因
            </div>

            <div
              class="userInfoRight"
              v-if="this.userInfo.name != postInfo.publisher.name"
            >
              <div
                class="focusBtn"
                v-if="!postInfo.publisher.hasFollowed"
                @click.stop="focus(postInfo.publisher)"
              >
<!--                <img src="@/assets/png/communityFollow.png" />-->
                <!--                                <svg-icon icon-class="btnAdd"></svg-icon>-->
                <span>关注</span>
              </div>
              <div
                class="alreadyFocus"
                v-else
                @click.stop="focus(postInfo.publisher)"
              >
               <span>已关注</span>
<!--                <img src="@/assets/png/noCommunityFollow.png" />-->
              </div>
            </div>
          </div>

          <TextOverflow
            :text="postInfo.title"
            :maxLines="3"
            :isPostItem="true"
            :postInfo="postInfo"
            :isTopping="postInfo.isTopping"
          >
            <template v-slot:default="{ clickToggle, expanded }">
              <div @click.stop="clickToggle" class="text-btn">
                {{ expanded ? "收起" : "全文" }}
              </div>
            </template>
          </TextOverflow>
         <!--    图片和视频     -->
          <div class="imgList" v-if="postInfo.seriesCover && postInfo.seriesCover.length > 2">
            <!--                        <div v-for="(i,j) in postInfo.seriesCover.slice(0,9)" :key="i" @click.stop="previewImg(j)">-->
            <!--                        <div v-for="(i, j) in postInfo.sourceURL != '' ? postInfo.seriesCover.slice(1,3) : postInfo.seriesCover.slice(0,3)" :key="i">-->
            <!--                            <ImgDecypt class="imgItem" :class="[{ 'large-img': j === 0, 'small-img': j > 0 }]" :src="i" :key="i">-->
            <!--                                <div class="maskNum" v-if="j == 2 && postInfo.seriesCover.length > 3">-->
            <!--                                    +{{ postInfo.seriesCover.length - 3 }}-->
            <!--                                </div>-->
            <!--                            </ImgDecypt>-->
            <!--                        </div>-->
            <div class="left-box">
              <ImgDecypt :src="postInfo.seriesCover[0]" class="large-img" />
            </div>
            <div class="right-box">
              <div
                v-for="(i, j) in postInfo.sourceURL != ''
                  ? postInfo.seriesCover.slice(1, 2)
                  : postInfo.seriesCover.slice(1, 3)"
                :key="i"
                class="small-img-container"
              >
                <ImgDecypt class="small-img" :src="i">
                  <div
                    class="maskNum"
                    v-if="j == 1 && postInfo.seriesCover.length > 3"
                  >
                    +{{ postInfo.seriesCover.length - 3 }}
                  </div>
                </ImgDecypt>
              </div>
              <div v-if="postInfo.sourceURL" @click="playVideo" class="small-img small-video">
               <!--                    <div v-else-if="postInfo.newsType == 'SP'" class="videoContent">-->
               <!--                            <div class="mask">-->
               <!--                                <div class="playTime">-->
               <!--                                    {{ postInfo.playTime | videotime }}-->
               <!--                                </div>-->
               <!--                            </div>-->
               <img class="playBtn" src="@/assets/png/playBtn.png" />
               <ImgDecypt class="Img" :src="postInfo.cover">
                <div
                  class="maskNum"
                  v-if="postInfo.seriesCover.length > 3"
                >
                 +{{ postInfo.seriesCover.length - 3 }}
                </div>
               </ImgDecypt>
              </div>
            </div>
          </div>
         <!--    视频     -->
          <div class="imgList" v-else>
           <div @click="playVideo" class="small-img">
            <img class="playBtn" src="@/assets/png/playBtn.png" />
            <ImgDecypt class="videoImg" :src="postInfo.cover" />
           </div>
          </div>
          <!--                    <div v-if="postInfo.sourceURL != null" @click="playVideo" class="videoContent">-->
          <!--&lt;!&ndash;                    <div v-else-if="postInfo.newsType == 'SP'" class="videoContent">&ndash;&gt;-->
          <!--                        <div class="mask">-->
          <!--                            <div class="playTime">-->
          <!--                                {{ postInfo.playTime | videotime }}-->
          <!--                            </div>-->
          <!--                        </div>-->
          <!--                        <img class="playBtn" src="@/assets/png/playBtn.png"/>-->
          <!--                        <ImgDecypt class="videoImg" :src="postInfo.cover"/>-->
          <!--                    </div>-->
          <div class="tagBox" v-if="postInfo.activeTagName">
            <div
              class="tagItem"
              v-for="tagItem in postInfo.tags"
              :key="tagItem.id"
              :class="postInfo.activeTagName.name == tagItem.name ? 'activeTag' : ''"
              @click.stop="tagJump(tagItem)"
            >
              <svg-icon class="tagPrefix" icon-class="tagPrefix" />
              <span>{{ tagItem.name }}</span>
            </div>
          </div>
          <div class="tagBox" v-else>
             <div
               class="tagItem"
               v-for="tagItem in postInfo.tags"
               :key="tagItem.id"
               @click.stop="tagJump(tagItem)"
             >
               <svg-icon class="tagPrefix" icon-class="tagPrefix" />
               <span>{{ tagItem.name }}</span>
             </div>
           </div>

          <div class="postMenu" v-if="!hiddenOtp">
            <div class="menuItme">
              <div class="fireIcon"></div>
              <div class="menuNum">{{ postInfo.playCount | watchCount }}</div>
            </div>

            <div class="menuBox flex-center-center">
              <div class="menuItme" @click.stop="comment">
                <!--                            <div class="greyMessage"></div>-->
                <svg-icon
                  icon-class="greyMessage"
                  class="greyMessage"
                ></svg-icon>
                <div class="menuNum">
                  {{ postInfo.commentCount | watchCount }}
                </div>
              </div>

              <div class="menuItme" @click.stop="like">
                <svg-icon
                  class="greyHeart"
                  :icon-class="
                    postInfo.vidStatus.hasLiked ? 'redHeart' : 'grey_heart'
                  "
                ></svg-icon>
                <div class="menuNum">{{ postInfo.likeCount | watchCount }}</div>
              </div>

              <div class="menuItme" @click.stop="reward">
<!--                <div-->
<!--                  class="rewardCoin"-->
<!--                  :class="{ rewardedCoin: postInfo.rewarded > 0 }"-->
<!--                ></div>-->
                <div class="rewardedCoin"></div>
                <div class="menuNum">
                  {{ parseInt(postInfo.rewarded) | watchCount }}
                </div>
              </div>
            </div>

            <!--                        <div class="menuItme" @click.stop="openVipPopup(postInfo)">-->
            <!--&lt;!&ndash;                            <div class="shareIcon"></div>&ndash;&gt;-->
            <!--                            <svg-icon icon-class="shareIcon" class="shareIcon"></svg-icon>-->
            <!--                            <div class="menuNum">分享</div>-->
            <!--                        </div>-->
          </div>
        </div>
      </div>
      <!--          <div class="focusBtn" v-if="!postInfo.publisher.hasFollowed" @click.stop="focus(postInfo.publisher)">-->
      <!--              <svg-icon icon-class="btnAdd"></svg-icon>-->
      <!--              <span>关注</span>-->
      <!--          </div>-->
      <!--          <div class="alreadyFocus" v-else @click.stop="focus(postInfo.publisher)">-->
      <!--              <span>已关注</span>-->
      <!--          </div>-->
    </div>

    <!--        <div class="bottomLine"></div>-->
  </div>
</template>

<script>
import TextOverflow from "@/components/TextOverflow";
import ImgDecypt from "@/components/ImgDecypt";
import { cancleZan, careOrcancle, zan } from "@/api/user";
import { setSessionItem } from "@/utils/longStorage";
import { Toast } from "vant";
import { mapGetters } from "vuex";
import { fromNowTimeToDate3 } from "@/utils/index.js";

export default {
  components: {
    ImgDecypt,
    TextOverflow,
    // Forward,
  },
  props: {
    postInfo: {
      type: Object,
      required: true,
      default: () => {
        return {};
      },
    },
    isCreateCenter: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    isShowHead: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    hiddenOtp: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
  },
  computed: {
    ...mapGetters({
      userInfo: "userInfo",
    }),
  },
  data() {
    return {};
  },
  created() {
    this.$bus.$on("callDelPost", this.delPost);
    // console.log(this.postInfo)
  },
  methods: {
   fromNowTimeToDate3,
    openVipPopup(postInfo) {
      this.$bus.$emit("openSharePopup", postInfo);
    },
    // 跳转用户主页
    jumpUserHome(item) {
      this.$router.push({
        path: "/userHomePage",
        query: {
          uid: item.uid,
        },
      });
    },
    //点赞
    async like() {
      let isLike = this.postInfo?.vidStatus?.hasLiked;
      let id = this.postInfo.id;
      let req = {
        objID: id,
        type: this.postInfo.newsType == "COVER" ? "image" : "video",
      };
      try {
        if (isLike) {
          let res = await this.$Api(cancleZan, req);
          if (res.code === 200) {
            this.postInfo.vidStatus.hasLiked = false;
            this.postInfo.likeCount--;
            Toast("取消点赞");
            return;
          }
          Toast(res.tip || "取消失败");
        } else {
          let res = await this.$Api(zan, req);
          if (res.code === 200) {
            this.postInfo.vidStatus.hasLiked = true;
            this.postInfo.likeCount++;
            Toast("点赞成功");
            return;
          }
          Toast(res.tip || "点赞失败");
        }
      } catch (e) {
        Toast("操作失败");
      }
    },
    // 帖子详情
    details(item) {
      // console.log(this.$route)
      if (this.$route.path == "/postDetails") {
        return;
      }
      // if(item.sourceURL){
      //   this.playVideo(item);
      //   return;
      // }
      this.$router.push({
        path: "/postDetails",
        query: {
          videoID: item.id,
        },
      });
      // if (item?.vidStatus?.hasPaid) {
      //   this.$router.push({
      //     path:"/postDetails",
      //     query:{
      //       videoID: item.id
      //     },
      //   })
      // } else if (item.coins > 0) {
      //   this.$bus.$emit("vipPopup", {
      //     id: this.postInfo.id,
      //     state: 2,
      //     goldenNum: item.coins,
      //     closeBtn: () => {
      //       // console.log(123123)
      //       this.$bus.$emit("closeVipPopup");
      //     },
      //     buySuccess: () => {
      //       item.vidStatus.hasPaid = true;
      //       this.$bus.$emit("closeVipPopup");
      //       // console.log(123123)
      //     },
      //   });
      // } else if (this.userInfo.isVip) {
      //   /**
      //    * 图片预览
      //    */
      //    this.$router.push({
      //     path:"/postDetails",
      //     query:{
      //       videoID: item.id
      //     }
      //   })
      // } else {
      //   this.$bus.$emit("vipPopup", {
      //     id: this.postInfo.id,
      //     state: 1,
      //     goldenNum: this.postInfo.coins,
      //     closeBtn: () => {
      //       this.$bus.$emit("closeVipPopup");
      //     },
      //     buySuccess: () => {
      //       item.vidStatus.hasPaid = true;
      //       this.$bus.$emit("closeVipPopup");
      //       // console.log(123123)
      //     },
      //   });
      // }
    },
    // 话题跳转
    tagJump(item) {
      this.$router.push({
        path: "/topicDetails",
        query: {
          id: item.id,
        },
      });
    },
    // 关注/取消关注
    async focus(item) {
      let req = {
        followUID: item.uid,
        isFollow: !item.hasFollowed,
      };
      let res = await this.$Api(careOrcancle, req);

      if (res && res.code == 200) {
        if (!item.hasFollowed) {
          item.hasFollowed = true;
          this.$toast({
            message: "关注成功",
            position: "top",
          });
        } else {
          item.hasFollowed = false;
          this.$toast({
            message: "取消关注",
            position: "top",
          });
        }
        let focusData = {
          focusSta: item.hasFollowed,
          uid: item.uid,
        };
        this.$emit("focusOn", focusData);
      }
    },

    // 打赏
    reward() {
      this.$store.commit("user/SET_REWAE", {
        show: true,
        info: this.postInfo,
      });
    },
    // 评论
    comment() {
      this.$store.commit("user/SET_COMMENTPOP", {
        show: true,
        info: this.postInfo,
      });
    },
    // 删除
    delPost() {
      this.$emit("delPost", this.postInfo);
    },
    // 查看原因
    checkReview() {
      // let reason = encodeURIComponent(this.postInfo.reason)
      // console.log(encodeURIComponent(reason))
      let dataStr = encodeURI(JSON.stringify(this.postInfo));
      this.$router.push(`/reviewResult?data=${this.encodeBase64(dataStr)}`);
    },
    // 转base64
    encodeBase64(str) {
      let base64Str = Buffer.from(str, "utf-8").toString("base64");
      return base64Str;
    },
    playVideo() {
      if (this.$route.path == "/postDetails") {
        setSessionItem("currentWorks", JSON.stringify(this.postInfo));
        this.$router.push({
          path: "/shortVideo",
        });
      }
    },

    // 图片预览
    previewImg(index) {
      if (this.postInfo?.vidStatus?.hasPaid) {
        this.$router.push({
          name: "PicturePreview",
          params: {
            imgs: this.postInfo.seriesCover,
            index: index,
          },
        });
      } else if (this.postInfo.coins > 0) {
        this.$bus.$emit("vipPopup", {
          id: this.postInfo.id,
          state: 2,
          goldenNum: this.postInfo.coins,
          closeBtn: () => {
            // console.log(123123)
            this.$bus.$emit("closeVipPopup");
          },
          buySuccess: () => {
            this.postInfo.vidStatus.hasPaid = true;
            this.$bus.$emit("closeVipPopup");
          },
        });
      } else if (this.userInfo.isVip) {
        /**
         * 图片预览
         */
        this.$router.push({
          name: "PicturePreview",
          params: {
            imgs: this.postInfo.seriesCover,
            index: index,
          },
        });
      } else {
        this.$bus.$emit("vipPopup", {
          id: this.postInfo.id,
          state: 1,
          goldenNum: this.postInfo.coins,
          closeBtn: () => {
            this.$bus.$emit("closeVipPopup");
          },
          buySuccess: () => {
            this.postInfo.vidStatus.hasPaid = true;
            this.$bus.$emit("closeVipPopup");
          },
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.postItem {
  color: #000;
  padding: 0 10px;
  box-sizing: border-box;
  background: #fff;
  // border-bottom: 1px solid #e6e6e6;

  .reviewStatus {
    margin-top: 18px;
    font-size: 15px;
  }

  .optBox {
    display: flex;
    align-items: center;

    .optBtn {
      font-size: 12px;
      padding: 4px 13px 3px;
      margin-right: 12px;
      border-radius: 50px;
      margin-top: 18px;
      box-sizing: border-box;
      background: #e6e6e6;
    }
  }

  .text-overflow {
    margin-top: 12px;
    position: relative;
    .text-btn {
     font-family: Dream Han Sans TC;
     font-size: 12px;
     font-weight: 900;
     line-height: 18px;
     color: #B09FD9;
     position: absolute;
     right: 0;
     bottom: 3px;
     background: #fff;
    }
  }

  .imgList {
    //display: grid;
    //grid-template-columns: 1fr 1fr 1fr;
    //grid-row-gap: 3px;
    //grid-column-gap: 3px;
    display: flex;
    margin: 12px 0;
    /deep/ img {
      border-radius: 8px;
    }

    .left-box {
      width: 233px;
      height: 233px;
      margin-right: 12px;
    }

    .right-box {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 112px;
      height: 233px;
    }

    .large-img {
      width: 100%;
      height: 100%;
    }

    .small-img-container {
      width: 112px;
      height: 112px;
    }
    .small-video {
     padding-top: 12px;
    }

    .small-img {
      width: 100%;
      height: 100%;
      position: relative;
      .playBtn {
        width: 18px;
        height: 21px;
        position: absolute;
        z-index: 1;
        left: 50%;
        top: 50%;
        //margin-top: 115px;
        //margin-left: 80px;
        transform: translate(-50%, -50%);
      }

      .maskNum {
        position: absolute;
        bottom: 4px;
        right: 4px;
        width: 40px;
        height: 20px;
        color: #f1f1f1;
        background: rgba(0, 0, 0, 0.6);
        border-radius: 8px;
        font-size: 12px;
        // border-radius: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .videoImg {
       height: 233px;
      }
    }

    .imgItem {
      //width: 112px;
      //height: 112px;
      // background: #1b164c;
      position: relative;

      /deep/ img {
        border-radius: 8px;
      }

      .playBtn {
        width: 18px;
        height: 21px;
        position: absolute;
        z-index: 1;
        left: 46px;
        top: 46px;
        //margin-top: 115px;
        //margin-left: 80px;
        transform: translate(-50%, -50%);
      }

      .maskNum {
        position: absolute;
        bottom: 4px;
        right: 4px;
        width: 40px;
        height: 20px;
        color: #f1f1f1;
        background: rgba(0, 0, 0, 0.6);
        border-radius: 8px;
        font-size: 12px;
        // border-radius: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .videoContent {
    margin: 9px 0;
    position: relative;

    // margin-left: 12px;
    .playBtn {
      width: 18px;
      height: 21px;
      position: absolute;
      z-index: 2;
      margin-top: 115px;
      margin-left: 80px;
      transform: translate(-50%, -50%);
    }

    .mask {
      width: 160px;
      box-sizing: border-box;
      position: absolute;
      bottom: 0;
      height: 60px;
      background: linear-gradient(180deg, transparent, rgba(0, 0, 0, 0.9));
      z-index: 2;
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
    }

    .playTime {
      // position: absolute;
      // z-index: 2;
      // margin-top: 205px;
      // margin-left: 115px;
      color: #fff;
      margin: 0 12px 9px 0;
    }

    .videoImg {
      width: 160px;
      height: 230px;

      /deep/ .van-image {
        // border-radius: 6px;
        background: #e6e6e600 !important;
      }

      /deep/ .van-image__img {
        // border-radius: 6px;
      }

      /deep/ .van-image__error {
        background-color: #e6e6e600 !important;
      }
    }
  }

  .tagBox {
    display: flex;
    align-items: center;
    margin-top: 12px;
    flex-wrap: wrap;

    .tagItem {
      max-width: 69px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      color: rgba(176, 159, 217, 1);
      font-weight: 400;
      margin-right: 5px;
      font-size: 12px;
      //padding: 3px 8px;
      //border-radius: 16px;
      //background: rgba(148, 214, 218, 0.30);
      margin-bottom: 8px;
      display: flex;
      align-items: center;
      .tagPrefix {
        width: 14px;
        height: 14px;
        vertical-align: middle;
      }
      span {
        vertical-align: middle;
        margin-left: 4px;
      }
    }
    .activeTag {
     color: rgba(255, 95, 102, 1);
    }
  }

  .postMenu {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 12px 0;

    .menuBox > div {
      margin-left: 44px;
    }

    .menuItme {
      font-size: 12px;
      color: #333333;
      display: flex;
      //flex-direction: column;
      align-items: center;
      justify-content: center;

      .menuNum {
        margin-left: 7px;
      }

      .fireIcon {
        width: 16px;
        height: 16px;
        background: url("../../assets/png/fire.png") no-repeat;
        background-size: 100% 100%;
      }

      .greyHeart {
        width: 20px;
        height: 20px;
      }

      .redHeart {
        width: 20px;
        height: 20px;
      }

      .shareIcon {
        width: 20px;
        height: 20px;
        background: url("../../assets/png/shareIcon.png") no-repeat;
        background-size: 100% 100%;
      }

      .greyMessage {
        width: 20px;
        height: 20px;
        //background: url('../../assets/png/greyMessage.png') no-repeat;
        //background-size: 100% 100%;
      }

      .rewardCoin {
        width: 20px;
        height: 20px;
        background: url('../../assets/png/reward_coin.png') no-repeat;
        background-size: 100% 100%;
      }

      .rewardedCoin {
        width: 20px;
        height: 20px;
        background: url("../../assets/png/rewarded_coin.png") no-repeat;
        background-size: 100% 100%;
      }

      .greyMessage {
        width: 20px;
        height: 20px;
      }

      .shareIcon {
        width: 20px;
        height: 20px;
      }
    }
  }
}

.communityHead {
  padding-top: 20px;
  padding-bottom: 12px;
  // padding: 0 12px;
  margin-top: 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .userContent {
    display: flex;
    align-items: start;

    .userInfoBox {
      width: calc(100vw - 20px);
      //padding-left: 11px;

      .userInfo {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .userName {
        font-size: 15px;
        color: rgb(0, 0, 0);
        display: flex;
        align-items: center;

        .portrait {
          width: 42px;
          height: 42px;
          background: rgb(255, 255, 255);
          border-radius: 50%;
          flex-shrink: 0;
          position: relative;

          .authIcon {
            position: absolute;
            right: 0;
            bottom: 0;
            width: 15px;
            height: 15px;
          }
          .avatarBorder {
           width: 50px;
           height: 50px;
           position: absolute;
           top: -4px;
           left: -4px;
           z-index: 1;
          }
        }

        .nameBox {
          margin-left: 8px;
          .names {
           height: 20px;
           display: flex;
           align-items: center;
            .supreme {
              color: rgb(255, 255, 255);
              font-size: 8px;
              background: rgb(255, 69, 0);
              border-radius: 15px;
              padding: 2px 8px;
              margin-left: 12px;
            }
            .vip {
              width: 13px;
              height: 14px;
              margin-left: 5px;
            }
            .superExpire {
             margin-left: 3px;
             width: 53px;
             height: 17px;
            }
          }
        }

        .topCommunity {
          color: rgb(255, 255, 255);
          font-size: 8px;
          background: rgb(215, 65, 85);
          border-radius: 15px;
          padding: 2px 8px;
          margin-left: 12px;
        }

        .merchant {
          color: rgb(255, 255, 255);
          font-size: 8px;
          background: rgb(255, 0, 216);
          border-radius: 15px;
          padding: 2px 8px;
          margin-left: 12px;
        }

        .blogger {
          color: rgb(255, 255, 255);
          font-size: 8px;
          background: rgb(255, 139, 39);
          border-radius: 15px;
          padding: 2px 8px;
          margin-left: 12px;
        }
      }

      .releaseTime {
        font-size: 12px;
        color: rgb(153, 153, 153);
        margin-top: 6px;

        display: flex;
        align-items: center;

        .sexIcon {
          width: 16px;
          height: 16px;
          margin-right: 4px;
        }
      }
    }
  }

  .cause {
    font-family: "Dream Han Sans TC";
    font-size: 12px;
    font-style: normal;
    font-weight: 900;
    line-height: 18px;
    width: 80px;
    height: 26px;
    background: url("../../assets/png/btnBg.png");
    background-size: 100% 100%;
    color: #b09fd9;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .btnIcon {
    width: 12px;
    height: 12px;
    margin-right: 4px;
  }

  .focusBtn {
    width: 63px;
    height: 26px;
    //font-size: 12px;
    //padding: 3px 10px;
    //background: rgb(113, 69, 231);
    //border-radius: 12.5px;
    //color: #94D6DA;
    //border: solid #94D6DA 1px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid transparent;
    border-radius: 8px;
    background-clip: padding-box, border-box;
    background-origin: padding-box, border-box;
    background-image: linear-gradient(to right, #fff, #fff), linear-gradient(160deg, #0000000D, #D8D8D80D);
    box-sizing: border-box;
    span {
     color: rgba(176, 159, 217, 1);
     font-family: Dream Han Sans TC;
     font-size: 12px;
     font-weight: 900;
     line-height: 18px;
     text-align: center;
    }
    //img {
    //  width: 100%;
    //  height: 100%;
    //}
  }

  .alreadyFocus {
    width: 63px;
    height: 26px;
    //font-size: 12px;
    //padding: 3px 12px;
    //background: #B3B3B3;
    //border-radius: 12.5px;
    //color: #FFFFFF;
    background: #F5F5F5;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    box-sizing: border-box;
    //border: solid #94D6DA 2px;
    span {
     color: #999999;
     font-family: Dream Han Sans TC;
     font-size: 12px;
     font-weight: 900;
     line-height: 18px;
     text-align: center;
    }
    img {
      width: 100%;
      height: 100%;
    }
  }
}

.bottomLine {
  padding: 0 12px;
  box-sizing: border-box;
  width: 100%;
  height: 1px;
  background: #e6e6e6;
}

.isPostDetails {
  border: none;
}
</style>
